<template>
  <v-list class="pt-1">
    <v-list-item
      class="px-0"
    >
      <div class="d-flex align-center flex-grow-1 flex-wrap">
        <div class="me-2">
          <span class="text-no-wrap">
            {{ customerType === '' ? 'Others' : customerType }}
          </span>
        </div>
        <v-spacer></v-spacer>
        <div class="d-flex align-center">
          <span v-if="summary" class="text--primary me-1">
            {{ summary[customerType] || 0 }}
          </span>
        </div>
      </div>
    </v-list-item>
  </v-list>
</template>

<script>
import { inject } from '@vue/composition-api'

export default {
  props: {
    customerType: {
      type: String,
      required: true,
    },
    summary: {
      type: Object,
      required: false,
    },
  },
  setup() {
    const t = inject('t')

    return {
      t,
    }
  },
}
</script>
